import { LOAD_ALERT, LOADING_ALERT } from "../constant/actionType";

const initialState = {
    loadingAlert: true,
    alertList: [],
};

const alertReducer = (state = initialState, action) => {
    if (action.type === LOADING_ALERT) {
        return { ...state, loadingAlert: true };
    }
    if (action.type === LOAD_ALERT) {
        // console.log(action)
        return { ...state, loadingAlert: false, alertList: action.payload };
    }
    return state;
};

export default alertReducer;

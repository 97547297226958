import React from "react";
import App from "./src/App_new";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
export default function Root(props) {
    return (
        <Provider store={store}>
            <App
                setFocusedRiskID={props.setFocusedRiskID}
                ou_id={
                    JSON.parse(sessionStorage.getItem("CurrentUser")).current_ou
                }
            />
        </Provider>
    );
}

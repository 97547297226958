import {
    LOAD_ALERT,
    LOAD_RISK,
    LOAD_USER,
    LOADING_ALERT,
    LOADING_RISK,
    LOADING_USER,
} from "../constant/actionType";

export const loadingUser = () => {
    return { type: LOADING_USER };
};
export const loadUser = (payload) => {
    return { type: LOAD_USER, payload };
};

export const loadingAlert = () => {
    return { type: LOADING_ALERT };
};
export const loadAlert = (payload) => {
    return { type: LOAD_ALERT, payload };
};

export const loadingRisk = () => {
    return { type: LOADING_RISK };
};
export const loadRisk = (payload) => {
    return { type: LOAD_RISK, payload };
};

import React from "react";
import { Dashboard } from "./page/dashboard";
import { loadAlertApiCall } from "./api/alertApi";
import {
    loadAlert,
    loadingAlert,
    loadingRisk,
    loadingUser,
    loadRisk,
    loadUser,
} from "../redux/action/actions";
import { connect } from "react-redux";
import "./app.scss";
import "bootstrap/dist/css/bootstrap.css";
import { loadUserListApiCall } from "./api/userApi";
import { loadRiskApiCall } from "./api/riskApi";
import { ScreenClassProvider } from "react-grid-system";

const companyName = "AIM Demo";

const riskMatrixSetting = [
    ["3", "3", "4", "4", "4"],
    ["2", "3", "3", "4", "4"],
    ["1", "2", "3", "4", "4"],
    ["1", "1", "2", "3", "3"],
    ["1", "1", "2", "3", "3"],
];

const riskNumberPerRow = 2;

const categoryList = ["residual", "gross", "target"];

function reloadRisk() {
    console.log("manipulate reducer");
}

class App extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        loadUserListApiCall(
            this.props.loadingUserDispatch,
            this.props.loadUserDispatch
        );
        loadAlertApiCall(
            this.props.loadingAlertDispatch,
            this.props.loadAlertDispatch
        );
        loadRiskApiCall(
            this.props.loadingRiskDispatch,
            this.props.loadRiskDispatch,
            this.props.userList
        );
    }

    render() {
        const riskRating = () => {
            return this.props.riskList.filter((item) => {
                return item.isParentRisk;
            });
        };

        let riskList = this.props.riskList;
        if (this.props.userList.length !== 0) {
            riskList.forEach((risk, riskIndex) => {
                risk.owner.forEach((username, ownerIndex) => {
                    let display_name = this.props.userList.filter((user) => {
                        return user.username === username;
                    })[0];
                    if (display_name) {
                        riskList[riskIndex]["owner"][ownerIndex] =
                            display_name.display_name;
                    }
                });
            });
        }
        return (
            <ScreenClassProvider>
                <Dashboard
                    loading={
                        this.props.loadingAlert ||
                        this.props.loadingRisk ||
                        this.props.loadingUser
                    }
                    userList={this.props.userList}
                    companyName={companyName}
                    riskList={riskList}
                    riskMatrixSetting={riskMatrixSetting}
                    categoryList={categoryList}
                    riskNumberPerRow={riskNumberPerRow}
                    riskRating={riskRating()}
                    alertList={this.props.alertList}
                />
            </ScreenClassProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        alertList: state.alert.alertList,
        userList: state.user.userList,
        riskList: state.risk.riskList,
        loadingUser: state.user.loadingUser,
        loadingAlert: state.alert.loadingAlert,
        loadingRisk: state.risk.loadingRisk,
    };
};

const mapDispatchToProps = {
    loadAlertDispatch: loadAlert,
    loadingAlertDispatch: loadingAlert,
    loadUserDispatch: loadUser,
    loadingUserDispatch: loadingUser,
    loadRiskDispatch: loadRisk,
    loadingRiskDispatch: loadingRisk,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
// export { reloadRisk };

import React from "react";
import {
    mapping,
    RiskTower,
    RiskMatrix,
    DropDownListButton,
    Pill,
    SearchBar,
    Loading,
    dashboardStyle,
} from "@aim-mf/styleguide";
import { Charts } from "./component/Charts";
import { MatrixExplanation } from "./component/MatrixExplanation";
import { MatrixLikelihoodExplanation } from "./component/MatrixLikelihoodExplanation";
import { MatrixImpactExplanation } from "./component/MatrixImpactExplanation";
import { navigateToUrl } from "single-spa";

const parentRiskCountBackgroundColor =
    mapping["Color_Parent_Risk_Count_Background"];
const subRiskCountBackgroundColor = mapping["Color_Basic_Primary"] + "33";
const subRiskCountBackgroundColorSelected =
    mapping["Color_Basic_Primary"] + "FF";
const GroupRiskByCategory = (category, riskList) => {
    var resultList = riskList.filter((item) => {
        return item.isParentRisk && item.category === category;
    });
    resultList.map((riskitem) => {
        riskitem.subRisk = riskList.filter((risk) => {
            return risk.parentRisk_id === riskitem.sourceRiskID;
        });
    });
    let soloSubRisk = riskList.filter((risk) => {
        let parentIDList = resultList.map((risk) => {
            return risk.sourceRiskID;
        });
        return (
            risk.category === category &&
            !risk.isParentRisk &&
            !parentIDList.includes(risk.parentRisk_id)
        );
    });
    soloSubRisk.map((riskitem) => {
        riskitem.subRisk = [];
    });
    resultList.push(...soloSubRisk);
    return resultList;
};

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeList: [
                { name: "sustainability", label: "Sustainability" },
                { name: "ABC", label: "ABC" },
                { name: "fraud", label: "Fraud" },
                { name: "dataPrivacy", label: "Data Privacy" },
                { name: "cyberSecurity", label: "Cyber Security" },
                { name: "criticalInfra", label: "Critical Infra" },
                { name: "others", label: "Others" },
            ],
            typeFilterList: [
                "All Type",
                "Sustainability",
                "ABC",
                "Fraud",
                "Data Privacy",
                "Cyber Security",
                "Critical Infra",
                "Others",
            ],
            riskList: this.props.riskList,
            riskMatrixSetting: this.props.riskMatrixSetting,
            categoryList: this.props.categoryList,
            riskNumberPerRow: this.props.riskNumberPerRow,
            riskRating: this.props.riskRating,
            //filter list [tier filter, rating filter, and search filter, and risk type]
            filterList: [1, "", "", ""],
            tierFilterList: ["All Tier", "Tier 1", "Tier 2", "Tier 3"],
            currentTier: 0,
            ratingFilterList: [
                "All Rating",
                "Very High",
                "High",
                "Medium",
                "Low",
            ],
            currentRating: 0,
            currentType: 0,
            MatrixExplanation: false,
            MatrixLikelihoodExplanation: false,
            MatrixImpactExplanation: false,
            levelFilter: "ParentRisk",
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            JSON.stringify(nextProps.riskList) !==
            JSON.stringify(this.props.riskList)
        ) {
            this.setState({ riskList: nextProps.riskList });
        }
        if (
            JSON.stringify(nextProps.riskRating) !==
            JSON.stringify(this.props.riskRating)
        ) {
            this.setState({ riskRating: nextProps.riskRating });
        }
    }

    HandleRiskTileClick = (ID) => {
        navigateToUrl("/risk-edit/" + ID + "/");
    };

    render() {
        // console.log(this.props.loading)
        const riskTileHoverCallback = (risk) => {
            this.setState({ riskRating: risk });
        };

        const riskTileUnhoverCallback = () => {
            this.setState({ riskRating: this.props.riskRating });
        };

        let displayData = this.filterData(this.props.riskList);
        let riskMatrixNumberData = displayData.filter((data) => {
            return data.isParentRisk === true;
        });
        var parentRiskCount = displayData.filter((item) => {
            return item.isParentRisk;
        }).length;
        var subRiskCount = displayData.length - parentRiskCount;
        return (
            <div>
                <div
                    style={
                        this.state.MatrixExplanation ||
                        this.state.MatrixImpactExplanation ||
                        this.state.MatrixLikelihoodExplanation ||
                        this.props.loading
                            ? BlurEffectStyle
                            : { dummy: "dummy" }
                    }
                >
                    <div style={dashboardStyle.TitleFontStyle}>
                        {" "}
                        {this.props.companyName}{" "}
                    </div>
                    <div
                        style={{
                            ...dashboardStyle.RiskCountFontStyle,
                            ...dashboardStyle.RiskCountAlignStyle,
                            width: "20.625rem",
                        }}
                    >
                        Total Risks: {displayData.length}
                    </div>
                    <div
                        style={{
                            ...dashboardStyle.RiskCountAlignStyle,
                            width: "8.125rem",
                        }}
                    >
                        <Pill
                            color={parentRiskCountBackgroundColor}
                            content={"Parent Risk: " + parentRiskCount}
                        />
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                    <div
                        style={{
                            ...dashboardStyle.RiskCountAlignStyle,
                            width: "8.125rem",
                        }}
                        className={"div_hover"}
                        onClick={() => {
                            this.setState({
                                levelFilter:
                                    this.state.levelFilter === "ParentRisk"
                                        ? "SubRisk"
                                        : "ParentRisk",
                            });
                        }}
                    >
                        <Pill
                            color={
                                this.state.levelFilter === "ParentRisk"
                                    ? subRiskCountBackgroundColor
                                    : subRiskCountBackgroundColorSelected
                            }
                            content={"Sub Risk: " + subRiskCount}
                        />
                    </div>
                    <div
                        style={dashboardStyle.RiskCountAlignStyle}
                        data-test={"dashboard_search"}
                    >
                        <SearchBar searchCallBack={this.onSearchCallBack} />
                    </div>
                    <div
                        style={dashboardStyle.RiskCountAlignStyle}
                        data-test={"dashboard_filter_tier"}
                    >
                        <DropDownListButton
                            data={this.state.tierFilterList}
                            value={this.state.tierFilterList[1]}
                            onChange={this.onTierChange}
                        />
                    </div>
                    <div
                        style={dashboardStyle.RiskCountAlignStyle}
                        data-test={"dashboard_filter_rating"}
                    >
                        <DropDownListButton
                            data={this.state.ratingFilterList}
                            value={this.state.ratingFilterList[0]}
                            onChange={this.onRatingChange}
                        />
                    </div>
                    <div
                        style={dashboardStyle.RiskCountAlignStyle}
                        data-test={"dashboard__filter_type"}
                    >
                        <DropDownListButton
                            DropDownButtonStyle={{ width: "11.25rem" }}
                            data={this.state.typeFilterList}
                            value={this.state.typeFilterList[0]}
                            onChange={this.onTypeChange}
                        />
                    </div>
                    <div style={dashboardStyle.RiskTowerStyle}>
                        <div style={dashboardStyle.RiskTowerDivStyle}>
                            <RiskTower
                                style={{ display: "inline-flex" }}
                                category={"Strategic"}
                                onHoverCallback={riskTileHoverCallback}
                                onUnhoverCallback={riskTileUnhoverCallback}
                                riskList={GroupRiskByCategory(
                                    "Strategic",
                                    displayData
                                )}
                                RiskTileClick={this.HandleRiskTileClick}
                            />
                        </div>
                        <div style={dashboardStyle.RiskTowerDivStyle}>
                            <RiskTower
                                style={{ display: "inline-flex" }}
                                category={"Operational"}
                                onHoverCallback={riskTileHoverCallback}
                                onUnhoverCallback={riskTileUnhoverCallback}
                                riskList={GroupRiskByCategory(
                                    "Operational",
                                    displayData
                                )}
                                RiskTileClick={this.HandleRiskTileClick}
                            />
                        </div>
                        <div style={dashboardStyle.RiskTowerDivStyle}>
                            <RiskTower
                                style={{ display: "inline-flex" }}
                                category={"Financial"}
                                onHoverCallback={riskTileHoverCallback}
                                onUnhoverCallback={riskTileUnhoverCallback}
                                riskList={GroupRiskByCategory(
                                    "Financial",
                                    displayData
                                )}
                                RiskTileClick={this.HandleRiskTileClick}
                            />
                        </div>
                        <div style={dashboardStyle.RiskTowerDivStyle}>
                            <RiskTower
                                style={{ display: "inline-flex" }}
                                category={"Technology"}
                                onHoverCallback={riskTileHoverCallback}
                                onUnhoverCallback={riskTileUnhoverCallback}
                                riskList={GroupRiskByCategory(
                                    "Technology",
                                    displayData
                                )}
                                RiskTileClick={this.HandleRiskTileClick}
                            />
                        </div>
                        <div style={dashboardStyle.RiskTowerDivStyle}>
                            <RiskTower
                                style={{ display: "inline-flex" }}
                                category={"Compliance"}
                                onHoverCallback={riskTileHoverCallback}
                                onUnhoverCallback={riskTileUnhoverCallback}
                                riskList={GroupRiskByCategory(
                                    "Compliance",
                                    displayData
                                )}
                                RiskTileClick={this.HandleRiskTileClick}
                            />
                        </div>
                    </div>
                    <div style={{ display: "inline-flex" }}>
                        <RiskMatrix
                            matrixSetting={this.state.riskMatrixSetting}
                            //riskRating={this.state.riskRating}
                            riskRating={
                                this.state.riskRating.length === 1
                                    ? this.state.riskRating
                                    : riskMatrixNumberData
                            }
                            singleRisk={this.state.riskRating.length === 1}
                            categoryList={this.state.categoryList}
                            handleLikelihoodInformationClick={() => {
                                this.setState({
                                    MatrixLikelihoodExplanation: true,
                                });
                            }}
                            handleImpactInformationClick={() => {
                                this.setState({
                                    MatrixImpactExplanation: true,
                                });
                            }}
                        />
                    </div>
                    <Charts
                        fullRiskList={this.props.riskList}
                        riskList={
                            this.state.riskRating.length === 1
                                ? this.state.riskRating
                                : riskMatrixNumberData
                        }
                        alertList={this.props.alertList}
                    />
                    <div style={{ height: "3.125rem" }} />
                </div>
                {this.state.MatrixExplanation && (
                    <MatrixExplanation
                        handleInformationClose={() => {
                            this.setState({ MatrixExplanation: false });
                        }}
                        matrixSetting={this.state.riskMatrixSetting}
                        categoryList={this.state.categoryList}
                    />
                )}
                {this.state.MatrixLikelihoodExplanation && (
                    <MatrixLikelihoodExplanation
                        data={LikelihoodParam}
                        handleInformationClose={this.handleInformationClose}
                    />
                )}
                {this.state.MatrixImpactExplanation && (
                    <MatrixImpactExplanation
                        data={ImpactParam}
                        handleInformationClose={this.handleInformationClose}
                    />
                )}
                {this.props.loading && (
                    <Loading
                        type={"bars"}
                        color={mapping["Color_Basic_Primary"]}
                    />
                )}
            </div>
        );
    }
    onSearchCallBack = (e) => {
        let filterList = this.state.filterList;
        filterList[2] = e.target.value;
        this.setState({ filterList: filterList });
    };

    onTierChange = (event) => {
        let tier = event.value;
        let tierIndex = this.state.tierFilterList.findIndex((el) => {
            return el === tier;
        });
        let filterList = this.state.filterList;
        filterList[0] = tierIndex;
        this.setState({ filterList: filterList });
    };
    onRatingChange = (event) => {
        let rating = event.value;
        let ratingIndex = this.state.ratingFilterList.findIndex((el) => {
            return el === rating;
        });
        let filterList = this.state.filterList;
        filterList[1] = 5 - ratingIndex;
        this.setState({ filterList: filterList });
    };
    onTypeChange = (event) => {
        let type = event.value;
        let typeIndex = this.state.typeFilterList.findIndex((el) => {
            return el === type;
        });
        let filterList = this.state.filterList;
        filterList[3] = typeIndex;
        this.setState({ filterList: filterList });
    };
    TypeMatching = (risk) => {
        return (
            this.state.filterList[3] === 0 ||
            risk[this.state.typeList[this.state.filterList[3] - 1].name]
        );
    };
    filterData = (riskList) => {
        let displayData = this.state.riskList
            .filter((risk) => {
                return (
                    this.state.filterList[0] === 0 ||
                    risk.tier === this.state.filterList[0]
                );
            })
            .filter((risk) => {
                return (
                    this.state.filterList[1] === 5 ||
                    risk.residual.rating === this.state.filterList[1]
                );
            })
            .filter((risk) => {
                return this.TypeMatching(risk);
                // this.state.filterList[3] === 0 ||
                // (risk.it_risk === true && this.state.filterList[3] === 1) ||
                // (risk.non_it_risk === true &&
                //     this.state.filterList[3] === 2)
            });
        let searchContent = this.state.filterList[2].toLowerCase();
        if (searchContent !== "") {
            // find the risks that suits the search keywords (risk name and risk owner)
            let riskOnSearch = displayData.filter((riskItem) => {
                let matchOwner = false;
                riskItem.owner.forEach((owner) => {
                    matchOwner =
                        matchOwner ||
                        owner.toLowerCase().includes(searchContent);
                });
                let matchRiskName = riskItem.name
                    .toLowerCase()
                    .includes(searchContent);
                return matchRiskName || matchOwner;
            });

            // if match subrisk, can find its parentrisk information
            let parentRiskList = riskOnSearch
                .map((a) => a.parentRiskId)
                .filter((item) => {
                    return item != "";
                });
            let riskIDList = riskOnSearch.map((a) => a.id);

            parentRiskList.forEach((ID) => {
                // if (!riskIDList.includes(ID)) ;
                let newParentRisk = riskList.filter((propsRiskItem) => {
                    return propsRiskItem.id === ID;
                });
                if (newParentRisk.length > 0) {
                    riskOnSearch.push(newParentRisk[0]);
                }
            });
            displayData = riskOnSearch;
        }
        //console.log(displayData);
        if (this.state.levelFilter === "SubRisk") {
            displayData = displayData.filter((risk) => {
                return !risk.isParentRisk;
            });
        }
        return displayData;
    };
    handleInformationClose = () => {
        this.setState({
            MatrixExplanation: false,
            MatrixImpactExplanation: false,
            MatrixLikelihoodExplanation: false,
        });
    };
}

const BlurEffectStyle = {
    filter: "blur(0.625rem)",
};

let LikelihoodParam = [
    {
        Parameters: "Probability",
        Rare: "≤ 5% chance of occurring",
        Unlikely: "> 5% to 25% chance of occurring",
        Possible: "> 25% to 75% chance of occurring",
        Likely: "> 75% to 95% chance of occurring",
        "Almost Certain": "> 95% chance of occurring",
    },

    {
        Parameters: "Qualitative Descriptors",
        Rare: "Event may occur only in exceptional circumstances",
        Unlikely: "Event could occur at some time",
        Possible: "Event will occur at some time",
        Likely: "Event probably occur in most circumstances",
        "Almost Certain": "Event is expected to occur in most circumstances\n",
    },

    {
        Parameters: "Time to\ndisruption/occurrence",
        Rare: "More than 5 years",
        Unlikely: "> 3 to 5 years",
        Possible: "> 2 to 3 years",
        Likely: "> 1 to 2 years",
        "Almost Certain": "Less than 12 months",
    },
];
let ImpactParam = [
    {
        Parameters: "Operational Impact",
        Insignificant:
            "Disruption for less than 6 hours with negligible impact  on service levels  to students",
        Minor:
            "Critical functions  brought to a temporary  disruption (6 hrs to <12 hrs) or impact on service levels  to students",
        Moderate:
            "Critical functions  brought to a moderate  disruption (12 hrs to <24 hrs ) or  moderately  affecting service  levels to students",
        Major:
            "Critical functions  brought to a major  disruption (1 to <3 days) or severely  affecting service  levels to students",
        Severe:
            "Critical functions  brought to a  prolonged  disruption (3 days or more) or crippling service levels to  students",
    },
    {
        Parameters: "Management  Effort",
        Insignificant: "Matters can be resolved by junior staff",
        Minor: "Matters can be resolved by managers",
        Moderate: "Matters can be resolved by the Head of Departments",
        Major:
            "Matters escalated up to the Chief Executive Officers and/ or President",
        Severe: "Matters escalated up to the board or council level",
    },
    {
        Parameters: "Workplace Health\nand Safety",
        Insignificant:
            "No injuries to employees or third parties (customers or vendors)",
        Minor:
            "No or minor injuries to employees or third parties (customers or vendors)",
        Moderate:
            "Out-patient medical treatment required for employees or third parties (customers or vendors)",
        Major:
            "Limited in-patient care required for employees or third parties (customers or vendors)",
        Severe:
            "Significant injuries or fatalities to employees or third parties (customers or vendors)",
    },
    {
        Parameters: "Impact On\nGrowth Rate",
        Insignificant: "No change on last year’s total enrolment",
        Minor: "> 0% to 0.5% decrease  on last year’s total enrolment",
        Moderate: "> 0.5% to 1% decrease on last year’s total enrolment",
        Major: "> 1% to 3% decrease on last year’s total enrolment",
        Severe: "> 3% decrease on last year’s total enrolment",
    },
    {
        Parameters: "Reputation",
        Insignificant:
            "Incidents and/or publicity on matters that have no consequence or noticeable impact on SUSS’s  \n" +
            "reputation​",
        Minor:
            "Incidents and/or publicity which can be easily mitigated that have minimal consequence or impact on SUSS’s  \n" +
            "reputation",
        Moderate:
            "Incidents and/or publicity that have escalated to a level that have some impact on SUSS’s reputation and/or brought to attention of relevant Government authorities​\n",
        Major:
            "Incidents and/or publicity that have escalated to a level that have considerable impact on SUSS’s reputation and/or resulted in official inquiry by relevant Government \n" +
            "authorities​\n",
        Severe:
            "Incidents and/or publicity that have escalated to a level that have serious impact on SUSS’s reputation and/or resulted in official censure by relevant Government \n" +
            "authorities​\n",
    },
    {
        Parameters: "Reputation",
        Insignificant:
            "Written or email complains resolved internally  without any negative publicity​\n",
        Minor:
            "Repeated written or email complaints by stakeholders resolved internally without negative publicity​\n",
        Moderate:
            "Repeated written or email complaints by stakeholders that are not resolved, with the potential to be prolonged​\n" +
            "Or Complaints on social media​\n" +
            "(> 1 day for social media; > 3 days for  written letter/email)\n",
        Major:
            "Publicity in local media and social media​\n" +
            "(> 3 days for social media; > 5 days for mainstream media)​​\n",
        Severe:
            "Widespread and prolonged negative publicity in local media and social media​ (> 5 days for social media; > 1 week on mainstream media)​\n" +
            "​\n" +
            "*Any fraud reported is deemed severe\n",
    },
    {
        Parameters: "Financial Loss",
        Insignificant: "≤ 1%\n" + "(≤ ~$0.4m)",
        Minor: "> 1% to 2.5%\n" + "(> ~$0.4m to $1m)",
        Moderate: "> 2.5% to 5%\n" + "(> ~$1m to $2m)",
        Major: "> 5% to 10%\n" + "(> ~$2m to $4m)",
        Severe: "> 10%\n" + "(> ~$4m)",
    },
];
export { Dashboard };

import { fetchAlertAPIWithPermission } from "@aim-mf/api";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";

const loadAlertApiCall = (loading, load, alertType = "") => {
    loading();
    let data = {};
    if (alertType !== "") data.alert_type = alertType;
    const userSubscription = fetchAlertAPIWithPermission("getalerts/", {
        data: data,
    }).subscribe(
        (results) => {
            // console.log(results)
            load(results.data);
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
            AuthCheckAndAutofix(err, () => {
                loadAlertApiCall(loading, load, alertType);
            });
        }
    );
    return () => userSubscription.unsubscribe();
};

export { loadAlertApiCall };

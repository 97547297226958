import { fetchAdminAPIWithPermission } from "@aim-mf/api";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";

const loadUserListApiCall = (loading, load) => {
    loading();
    const userSubscription = fetchAdminAPIWithPermission(
        "auth/profile/list"
    ).subscribe(
        (results) => {
            load(results.data);
        },
        (err) => {
            console.log("err", err); // eslint-disable-line
            AuthCheckAndAutofix(err, () => {
                loadUserListApiCall(loading, load);
            });
        }
    );

    return () => userSubscription.unsubscribe();
};

export { loadUserListApiCall };

import { fetchAPIWithPermission } from "@aim-mf/api";
import { AuthCheckAndAutofix } from "@aim-mf/styleguide";

const loadRiskApiCall = (loading, load, userList) => {
    let param = {
        data: {
            isArchived: "NON-ARCHIVED",
            ou_id: JSON.parse(sessionStorage.getItem("CurrentUser")).current_ou,
        },
    };
    loading();
    const subscription = fetchAPIWithPermission(
        "getrisksbyou",
        param
    ).subscribe(
        (results) => {
            let newResults = { results: results, userList: userList };
            load(newResults);
        },
        (err) => {
                console.log("err", err); // eslint-disable-line
            AuthCheckAndAutofix(err, () => {
                loadRiskApiCall(loading, load);
            });
        }
    );

    return () => subscription.unsubscribe();
};

export { loadRiskApiCall };

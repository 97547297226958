import React from "react";
import {
    mapping,
    BarChart,
    Pill,
    StackBarChart,
    dashboardChartStyle,
} from "@aim-mf/styleguide";
import { navigateToUrl } from "single-spa";

let KRIPeriodCount = 6;

class Charts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getFields = (risks, field) => {
        let controls = [];
        risks.forEach((risk) => {
            risk[field].forEach((control) => {
                controls.push(control);
            });
        });
        return controls;
    };

    render() {
        let parentRisk = this.props.riskList;
        let subRisk = this.props.fullRiskList.filter((risk) => {
            return (
                this.props.riskList
                    .map((parentRisk) => parentRisk.sourceRiskID)
                    .indexOf(risk.parentRisk_id) !== -1
            );
        });
        let parentSubRisk = parentRisk.concat(subRisk);
        let chartMax = 10;
        let controlList = this.getFields(parentSubRisk, "controls");
        let APList = this.getFields(parentSubRisk, "actionplans");
        let KRIList = this.getFields(parentSubRisk, "KRIs");
        let riskAppetiteList = this.getFields(parentSubRisk, "riskappetite");
        let relevantAlert = this.props.alertList.filter((alert) => {
            return (
                parentSubRisk.map((risk) => risk.id).indexOf(alert.risk_id) !==
                -1
            );
        });
        // console.log(relevantAlert);
        // console.log(["control", controlList]);
        // console.log(["APList", APList]);
        // console.log(["KRIList", KRIList]);
        // console.log(["riskAppetiteList", riskAppetiteList]);

        let controlIndependentSource = 0;
        let controlManagementSource = 0;

        controlList.forEach((control) => {
            if (
                control.is_ia_assurance ||
                control.is_other_assurance ||
                (control.type === "manual" && control.is_CSA_completed)
            ) {
                controlIndependentSource = controlIndependentSource + 1;
            } else if (
                control.type === "automatic" ||
                control.is_management_assurance
            ) {
                controlManagementSource = controlManagementSource + 1;
            }
        });

        let completedActionPlan = APList.filter((AP) => {
            let TodayDate = new Date();
            if (AP.completed_date !== null) {
                return (
                    Date.parse(AP.completed_date) >
                    TodayDate.setMonth(TodayDate.getMonth() - 6)
                );
            }
        });

        return (
            <div style={dashboardChartStyle.ChartArrayWrapperStyle}>
                <DashboardChartBlock
                    title={"Control Strength"}
                    TopbarSection={1}
                    topBarContent={controlList.length + " Control in Document"}
                    data={ControlStrengthChartData(controlList)}
                    //max={chartMax}
                    height={"11.2rem"}
                />
                <DashboardChartBlock
                    title={"Control Sources of assurance"}
                    TopbarSection={2}
                    topBarData={[
                        controlManagementSource,
                        controlIndependentSource,
                    ]}
                    topBarFootnote={["Mgmt Assurance", "Independent Assurance"]}
                    data={[
                        ControlSourceChartData(controlList),
                        ControlSourceChartComplementaryData(controlList),
                    ]}
                    //max={controlList.length}
                    height={"11.9rem"}
                    stack={true}
                    footNote={"Total number of controls: " + controlList.length}
                />
                <DashboardChartBlock
                    title={"Action Plans"}
                    TopbarSection={1}
                    topBarContent={
                        completedActionPlan.length +
                        " action plan completed in past 6 Month"
                    }
                    data={ActionPlanChartData(APList, relevantAlert)}
                    //max={chartMax}
                    height={"11.2rem"}
                />
                <DashboardChartBlock
                    title={"key risk indicators"}
                    TopbarSection={1}
                    topBarContent={"14 KRI followup actions"}
                    pillFootnote={"Last collected on March 2019"}
                    data={KRIChartData(KRIList)}
                    //max={chartMax}
                    height={"11.2rem"}
                />
                <DashboardChartBlock
                    title={"Integrated Assurance"}
                    TopbarSection={1}
                    topBarContent={""}
                    data={[
                        {
                            category: "No. of Exceptions",
                            value: this.props.alertList.filter((alert) => {
                                return (
                                    alert.alert_type ===
                                    "Integrated Assurance Exceptions"
                                );
                            }).length,
                            color: "red",
                        },
                    ]}
                    //max={chartMax}
                    height={"11.2rem"}
                    width={"13.75rem"}
                    onBarClick={() => {
                        sessionStorage.setItem(
                            "alert_type",
                            "Integrated Assurance Exceptions"
                        );
                        sessionStorage.setItem("alerted_object_id", null);
                        sessionStorage.setItem(
                            "type_object_list",
                            JSON.stringify([])
                        );
                        sessionStorage.setItem(
                            "alerted_risk",
                            JSON.stringify({ name: "Back to Dashboard" })
                        );
                        sessionStorage.setItem(
                            "riskList",
                            JSON.stringify(this.props.riskList)
                        );
                        navigateToUrl("/alert-management/");
                    }}
                />
                <DashboardChartBlock
                    title={"Key Tolerance"}
                    TopbarSection={1}
                    topBarContent={
                        riskAppetiteList.length + " Tolerance Metrics"
                    }
                    data={KeyToleranceChartData(riskAppetiteList)}
                    //max={chartMax}
                    height={"11.9rem"}
                    width={"9.375rem"}
                />
            </div>
        );
    }
}

const DashboardChartBlock = (props) => {
    return (
        <div
            style={{
                ...dashboardChartStyle.ChartWrapperStyle,
                width: props.width ? props.width : "18.75rem",
            }}
        >
            <div style={dashboardChartStyle.sectionTitleStyle}>
                {props.title}
            </div>
            {props.TopbarSection === 2 ? (
                <ProgressPill
                    topBarData={props.topBarData}
                    topBarDataText={props.topBarFootnote}
                />
            ) : (
                <div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Pill
                            pillStyle={{
                                backgroundColor: "#4E5C75",
                                height: "1.625rem",
                                width: "87%",
                            }}
                            contentStyle={
                                dashboardChartStyle.topBarContentStyle
                            }
                            content={props.topBarContent}
                        />
                    </div>
                    {props.pillFootnote ? (
                        <div
                            style={{
                                ...dashboardChartStyle.topBarFootnoteFontStyle,
                                marginTop: "0.625rem",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {props.pillFootnote}
                        </div>
                    ) : (
                        <div style={{ height: "1.5rem" }} />
                    )}
                </div>
            )}
            {!props.stack && (
                <BarChart
                    max={props.max}
                    height={props.height}
                    width={props.width}
                    data={props.data}
                    onBarClick={props.onBarClick}
                />
            )}
            {props.stack && (
                <StackBarChart
                    max={props.max}
                    height={props.height}
                    width={props.width}
                    data={props.data}
                />
            )}
            {props.footNote && (
                <div style={dashboardChartStyle.footNoteStyle}>
                    {props.footNote}
                </div>
            )}
        </div>
    );
};

const ProgressPill = (props) => {
    const totalWidth = 262.5;
    const leftWidth = Math.min(
        Math.max(
            (totalWidth * props.topBarData[0]) /
                Math.max(props.topBarData[0] + props.topBarData[1], 1),
            20
        ),
        totalWidth - 20
    );
    const rightWidth = totalWidth - leftWidth;
    const LeftPartStyle = {
        ...dashboardChartStyle.topBarContentStyle,
        display: "inline-flex",
        height: "1.625rem",
        width: leftWidth / 16 + "rem",
        borderRadius: "6.5625rem 0 0 6.5625rem",
        backgroundColor: "#007ADC",
    };

    const RightPartStyle = {
        ...dashboardChartStyle.topBarContentStyle,
        display: "inline-flex",
        height: "1.625rem",
        width: rightWidth / 16 + "rem",
        borderRadius: "0 6.5625rem 6.5625rem 0",
        backgroundColor: "#4E5C75",
    };
    return (
        <div>
            <div style={{ margin: "auto", width: "16.4375rem" }}>
                <div style={LeftPartStyle}>{props.topBarData[0]}</div>
                <div style={RightPartStyle}>{props.topBarData[1]}</div>
            </div>
            <div
                style={{
                    display: "inline-flex",
                    marginLeft: "0.625rem",
                    width: "16.4375rem",
                }}
            >
                <div
                    style={{
                        ...dashboardChartStyle.topBarFootnoteFontStyle,
                        marginLeft: "0.625rem",
                    }}
                >
                    {props.topBarDataText[0]}
                </div>
                <div
                    style={{
                        ...dashboardChartStyle.topBarFootnoteFontStyle,
                        marginRight: 0,
                    }}
                >
                    {props.topBarDataText[1]}
                </div>
            </div>
        </div>
    );
};

const ControlStrengthChartData = (controlList) => {
    let strongControlNumber = controlList.filter((control) => {
        return control.strength === "Strong";
    }).length;

    let moderateControlNumber = controlList.filter((control) => {
        return control.strength === "Moderate";
    }).length;

    let weakControlNumber = controlList.filter((control) => {
        return control.strength === "Weak";
    }).length;

    let ControlStrengthChartData = [
        {
            strength:
                "Strong " +
                Math.round(
                    (strongControlNumber * 100) /
                        Math.max(controlList.length, 1)
                ) +
                "%",
            number: strongControlNumber,
            color: mapping["Color_Platform_RiskLow"],
            outline: "0.1875rem solid #00A6FF",
        },
        {
            strength:
                "Moderate " +
                Math.round(
                    (moderateControlNumber * 100) /
                        Math.max(controlList.length, 1)
                ) +
                "%",
            number: moderateControlNumber,
            color: mapping["Color_Platform_RiskMed"],
        },
        {
            strength:
                "Weak " +
                Math.round(
                    (weakControlNumber * 100) / Math.max(controlList.length, 1)
                ) +
                "%",
            number: weakControlNumber,
            color: mapping["Color_Platform_RiskHigh"],
        },
    ];
    return ControlStrengthChartData;
};

const ControlSourceChartComplementaryData = (controlList) => {
    let ControlCSACompleteNumber = controlList.filter((control) => {
        return control.is_CSA_performed;
    }).length;

    let ControlIANumber = controlList.filter((control) => {
        return control.is_ia_assurance;
    }).length;

    let ControlOtherNumber = controlList.filter((control) => {
        return control.is_other_assurance;
    }).length;
    let ControlSourceChartComplementaryData = [
        {
            strength:
                "CSA Performed\n" +
                Math.round(
                    (ControlCSACompleteNumber * 100) /
                        Math.max(controlList.length, 1)
                ) +
                "%",
            number: controlList.length - ControlCSACompleteNumber,
            color: "#EF8945",
            outline: "0.1875rem solid #00A6FF",
        },
        {
            strength:
                "Internal Audit\nPerformed " +
                Math.round(
                    (ControlIANumber * 100) / Math.max(controlList.length, 1)
                ) +
                "%",
            number: controlList.length - ControlIANumber,
            color: "#EF8945",
        },
        {
            strength:
                "Other\nAssurance " +
                Math.round(
                    (ControlOtherNumber * 100) / Math.max(controlList.length, 1)
                ) +
                "%",
            number: controlList.length - ControlOtherNumber,
            color: "#EF8945",
        },
    ];
    return ControlSourceChartComplementaryData;
};

const ControlSourceChartData = (controlList) => {
    // console.log(controlList)
    let ControlCSACompleteNumber = controlList.filter((control) => {
        return control.is_CSA_performed;
    }).length;

    let ControlIANumber = controlList.filter((control) => {
        return control.is_ia_assurance;
    }).length;

    let ControlOtherNumber = controlList.filter((control) => {
        return control.is_other_assurance;
    }).length;

    let ControlSourceChartData = [
        {
            strength:
                "CSA Performed\n" +
                Math.round(
                    (ControlCSACompleteNumber * 100) /
                        Math.max(controlList.length, 1)
                ) +
                "%",
            number: ControlCSACompleteNumber,
            color: "#FFBF47",
            outline: "0.1875rem solid #00A6FF",
        },
        {
            strength:
                "Internal Audit\nPerformed " +
                Math.round(
                    (ControlIANumber * 100) / Math.max(controlList.length, 1)
                ) +
                "%",
            number: ControlIANumber,
            color: "#FFBF47",
        },
        {
            strength:
                "Other\nAssurance " +
                Math.round(
                    (ControlOtherNumber * 100) / Math.max(controlList.length, 1)
                ) +
                "%",
            number: ControlOtherNumber,
            color: "#FFBF47",
        },
    ];
    return ControlSourceChartData;
};

const ActionPlanChartData = (APList, alertList) => {
    let today = new Date();
    let APClosed = APList.filter((AP) => {
        return AP.is_completed;
    }).length;
    let AlertClosed = alertList.filter((alert) => {
        return alert.status === "closed";
    }).length;
    let closed = APClosed + AlertClosed;
    let APOverdue = APList.filter((AP) => {
        return today > Date.parse(AP.deadline) && !AP.is_completed;
    }).length;
    let AlertOverdue = alertList.filter((alert) => {
        return alert.status !== "closed" && today > Date.parse(alert.due_date);
    }).length;
    let Overdue = APOverdue + AlertOverdue;
    let APOnTrack = APList.filter((AP) => {
        return (
            (today <= Date.parse(AP.deadline) &&
                !AP.is_completed &&
                AP.status === "On Track") ||
            AP.status === "In Progress"
        );
    }).length;
    let AlertOnTrack = alertList.filter((alert) => {
        return today <= Date.parse(alert.due_date) && alert.status === "open";
    }).length;
    let OnTrack = APOnTrack + AlertOnTrack;

    let actionPlanChartData = [
        {
            status: "Closed",
            number: closed,
            color: mapping["Color_Platform_RiskLow"],
        },
        {
            status: "Overdue",
            number: Overdue,
            color: mapping["Color_Platform_RiskHigh"],
        },
        {
            status: "On Track",
            number: OnTrack,
            color: mapping["Color_Platform_RiskLow"],
        },
    ];
    return actionPlanChartData;
};

const KRIChartData = (KRIList) => {
    let green = 0,
        amber = 0,
        red = 0,
        notDue = 0;
    KRIList.map((kri) => {
        if (kri.value.length === 0) {
            notDue += 1;
        } else if (kri.safe_zone === "low") {
            let value = kri.value
                .sort((a, b) => {
                    return a.period > b.period ? 1 : -1;
                })
                .slice(-KRIPeriodCount);
            let redValue = value.filter((value) => {
                return value.value > kri.upper_threshold;
            });
            let amberValue = value.filter((value) => {
                return (
                    value.value < kri.upper_threshold &&
                    value.value > kri.lower_threshold
                );
            });
            if (redValue.length > 0) {
                red += 1;
            } else if (amberValue.length > 0) {
                amber += 1;
            } else {
                green += 1;
            }
        } else if (kri.safe_zone === "high") {
            let value = kri.value
                .sort((a, b) => {
                    return a.period > b.period ? 1 : -1;
                })
                .slice(-KRIPeriodCount);
            let redValue = value.filter((value) => {
                return value.value < kri.lower_threshold;
            });
            let amberValue = value.filter((value) => {
                return (
                    value.value > kri.lower_threshold &&
                    value.value < kri.upper_threshold
                );
            });
            if (redValue.length > 0) {
                red += 1;
            } else if (amberValue.length > 0) {
                amber += 1;
            } else {
                green += 1;
            }
        }
    });
    let KRIChartData = [
        {
            threshold: "Green",
            number: green,
            color: mapping["Color_Platform_RiskLow"],
        },
        {
            threshold: "Amber",
            number: amber,
            color: mapping["Color_Platform_RiskMed"],
        },
        {
            threshold: "Red",
            number: red,
            color: mapping["Color_Platform_RiskHigh"],
        },
        {
            threshold: "Not Due",
            number: notDue,
            color: "#7F7F7F",
        },
    ];
    return KRIChartData;
};

const KeyToleranceChartData = (riskAppetite) => {
    let KeyToleranceChartData = [
        {
            threshold: "Breaches in\n Risk Tolerance\n",
            number: riskAppetite.length,
            color: "#922B8B",
        },
    ];
    return KeyToleranceChartData;
};

export { Charts };
